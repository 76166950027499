/* global window document  */
import { Elm } from '../../javascript/elm-apps.js';
import * as EventStream from './js/EventStream';
import SubscribeSentryLogger from '../Common/Error/SubscribeSentryLogger';
import type BatchedDataLayer from '../../javascript/lib/Batch/BatchedDataLayer';
import { BellroyError } from '../Common/Error/Types';
import { PortFromElm, PortToElm } from '../Common/ElmPorts';

type EventStreamElmApp = {
  ports: {
    eventStreamActions: PortToElm<EventStream.Action>;
    eventStreamResponses: PortFromElm<EventStream.Response>;
    error: PortFromElm<BellroyError>;
  };
};

export default function (batchedDataLayer: BatchedDataLayer) {
  const app: EventStreamElmApp = Elm.EventStream.Main.init({
    node: document.getElementById('EventStreamApp'),
  });
  const eventStream = new EventStream.EventStream(
    Object.assign(app.ports, {
      dataLayer: batchedDataLayer,
    }),
  );

  SubscribeSentryLogger(app.ports.error);

  window.EventStream = eventStream;

  return eventStream;
}
