import * as Segment from "@app/javascript/tracking/Segment";

const dialogOpenEvent = new Event("open");
const dialogBeforeOpenEvent = new CustomEvent("beforeOpen", {
  cancelable: true,
});

class Modal extends HTMLElement {
  private attrObserver: MutationObserver;
  private blockingModalsSelector = "dialog#cookie-dialog";
  dialog: HTMLDialogElement | undefined;

  constructor() {
    super();

    this.attrObserver = new MutationObserver((mutations, observer) => {
      mutations.forEach(async (mutation) => {
        if (mutation.attributeName === "open") {
          const dialog = mutation.target as HTMLDialogElement;

          const isOpen = dialog.hasAttribute("open");
          if (!isOpen) return;

          // Dispatch beforeOpen event and check if it was prevented
          const shouldOpen = dialog.dispatchEvent(dialogBeforeOpenEvent);
          if (!shouldOpen) {
            dialog.close();
            return;
          }

          dialog.dispatchEvent(dialogOpenEvent);
        }
      });
    });
  }

  private hasBlockingModalsOpen(): boolean {
    if (!this.blockingModalsSelector) {
      return false;
    }
    const blockingModals: HTMLDialogElement[] = Array.from(
      document.querySelectorAll(this.blockingModalsSelector),
    );
    return blockingModals.some((modal) => {
      return modal.open && modal !== this.dialog;
    });
  }

  connectedCallback(): void {
    const dialog = this.querySelector("dialog");
    if (!dialog) {
      console.error("Dialog element not found");
      return;
    }
    this.dialog = dialog;

    this.attrObserver.observe(this.dialog, {
      attributes: true,
    });

    // Add default beforeOpen handler to prevent multiple modals
    this.dialog.addEventListener("beforeOpen", (e) => {
      if (this.hasBlockingModalsOpen()) {
        e.preventDefault();
      }
    });
    this.dialog.addEventListener("open", () => {
      Segment.push("Modal Opened", {
        id: dialog.id,
      });
    });

    this.dialog.addEventListener("open", () => {
      const currentLazySlug = this.dialog?.dataset.lazySlug;
      if (currentLazySlug) {
        this.renderLazyContent(currentLazySlug);
      }
    });

    dialog.addEventListener("click", (e) => {
      if (e.target === dialog) {
        dialog.close();
      }
    });
  }

  disconnectedCallback(): void {
    this.attrObserver.disconnect();
  }

  renderLazyContent(slug: string): void {
    let contentContainer = this.dialog?.querySelector("[data-content]");
    if (!contentContainer) return;

    let contentBlock = document.createElement("content-block");
    contentBlock.setAttribute("slug", slug);
    contentContainer.replaceWith(contentBlock);
  }
}

customElements.define("bellroy-modal", Modal);

document.body.addEventListener("click", (e) => {
  const eTarget = e.target as HTMLElement;
  const target = (eTarget.closest("a") || eTarget) as HTMLElement;
  if (target.matches('[data-toggle="modal"]')) {
    e.preventDefault();
    const dialogId = target.dataset.target;
    const dialog = document.querySelector(dialogId) as HTMLDialogElement;
    dialog && dialog.showModal();
  }
});
