import type { EventStream, Event } from '../../elm/EventStream/js/EventStream';
import * as Segment from './Segment';

export type SegmentNameAndPayload = {
  name: string;
  payload: {
    gallery_image_index: number;
    gallery_image_count: number;
  };
};

export function makeSegmentNameAndPayload(event: Event): SegmentNameAndPayload | null {
  if (event?.eventData?.category !== 'gallery_viewed') {
    return null;
  }
  return {
    name: 'Gallery Viewed',
    payload: {
      gallery_image_index: event.eventData.galleryImageIndex,
      gallery_image_count: event.eventData.galleryImageCount,
    },
  };
}

export default function (eventStream: EventStream) {
  eventStream.addListener('generic.event', (event: Event) => {
    const segmentNameAndPayload = makeSegmentNameAndPayload(event);
    if (segmentNameAndPayload) {
      Segment.push(segmentNameAndPayload.name, segmentNameAndPayload.payload);
    }
  });
}
