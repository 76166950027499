import BatchedDataLayer from '../lib/Batch/BatchedDataLayer';
import ecommerceImpressionsMerger from '../lib/Batch/Merge/EcommerceImpressions';
const batchedDataLayer = new BatchedDataLayer({
  mergers: {
    'custom.product.list.view': ecommerceImpressionsMerger,
  },
});

import ReportWebVitals from '../report-web-vitals';
ReportWebVitals(batchedDataLayer);

// Polyfills
import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Suggested in https://github.com/zloirock/core-js/issues/940
function replaceChildren(...new_children) {
  // @ts-ignore
  while (this?.childNodes?.length) {
    // @ts-ignore
    this?.childNodes[0].remove();
  }
  // @ts-ignore
  this?.append(...new_children);
}

Document.prototype.replaceChildren ||= replaceChildren;
DocumentFragment.prototype.replaceChildren ||= replaceChildren;
Element.prototype.replaceChildren ||= replaceChildren;

import * as CustomerContext from '../lib/CustomerContext';
const customerContextPromise = CustomerContext.fetch();

import * as SiteConfiguration from '../lib/SiteConfiguration';
const siteConfigurationPromise = SiteConfiguration.fetch();

import Sentry from '../sentry/Sentry';
Sentry(siteConfigurationPromise);

// EventStream must be initialised before loading elm/*/init.js.
import EventStream from '../../elm/EventStream/init';
const eventStream = EventStream(batchedDataLayer);

import '../../elm/MiniCart/style.css';
import I18nFactory from '../helpers/I18n';
import ProductRange from '../../elm/Product/Range/init';

import WebComponents from '../web_components';

const i18nPromise = I18nFactory();
ProductRange(customerContextPromise, siteConfigurationPromise, i18nPromise, eventStream);
WebComponents(customerContextPromise, siteConfigurationPromise, i18nPromise, eventStream);

import loadTechTeamSearch from '../tech-team-search';
window.Bellroy = window.Bellroy || {};
window.Bellroy.loadTechTeamSearch = function () {
  loadTechTeamSearch(customerContextPromise, siteConfigurationPromise);
};

import loadRetailersMap from '../retailers-map';
window.Bellroy = window.Bellroy || {};
window.Bellroy.loadRetailersMap = function () {
  loadRetailersMap(siteConfigurationPromise);
};

import corporateGiftingEmailForm from '../corporate-gifting-email-form';
window.Bellroy = window.Bellroy || {};
window.Bellroy.corporateGiftingEmailForm = corporateGiftingEmailForm;

import TrackingConsentInteractions from '../tracking/consent-banner-interactions';
TrackingConsentInteractions(eventStream);

import TrackingGalleryView from '../tracking/gallery-viewed';
TrackingGalleryView(eventStream);

import TrackingProductView from '../tracking/product-view';
TrackingProductView(eventStream);

import TrackingProductListClick from '../tracking/product-list-click';
TrackingProductListClick(eventStream);

import TrackingProductListFilter from '../tracking/product-list-filter';
TrackingProductListFilter(eventStream);

import TrackingCheckoutStepCompleted from '../tracking/checkout-step-completed';
TrackingCheckoutStepCompleted(eventStream);

import TrackingPaymentInfoEntered from '../tracking/payment-info-entered';
TrackingPaymentInfoEntered(eventStream);

import TrackingCartAddLineItem from '../tracking/cart-add-line-item';
TrackingCartAddLineItem(eventStream);

import TrackingCartRemoveLineItem from '../tracking/cart-remove-line-item';
TrackingCartRemoveLineItem(eventStream);

import TrackingNewsletterSignup from '../tracking/newsletter-signup';
TrackingNewsletterSignup(eventStream);

import TrackingContentExpanded from '../tracking/content-expanded';
TrackingContentExpanded(eventStream);

import StoreRecentlyViewed from '../store-viewed';
StoreRecentlyViewed(eventStream);

import '../tracking/link-clicked';
