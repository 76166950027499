import * as Sentry from '@sentry/browser';
import SentryFirstSeenFilter from './SentryFirstSeenFilter';
import * as SiteConfiguration from '../lib/SiteConfiguration';

export default async function (siteConfigurationPromise: Promise<SiteConfiguration.Configuration>) {
  function containsViteLegacyIgnoreBreadcrumb(event: Sentry.Event): boolean {
    let viteIgnoreBreadcrumb = event.breadcrumbs?.find((breadcrumb) => {
      breadcrumb.category === 'console' &&
        breadcrumb.data?.message?.match(
          'vite: loading legacy chunks, syntax error above and the same error below should be ignored',
        );
    });

    return !!viteIgnoreBreadcrumb;
  }

  const firstSeenFilter = new SentryFirstSeenFilter();
  const config = await siteConfigurationPromise;

  if (config && (config.environment == 'staging' || config.environment == 'production')) {
    Sentry.init({
      dsn: 'https://445db31b18a0b1595f429e0e06466548@o22319.ingest.sentry.io/4506024103313408',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.extraErrorDataIntegration(),
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
      ],
      tracesSampleRate: 0.005,
      replaysOnErrorSampleRate: 0.005,
      ignoreErrors: [
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
        'The YouTube player is not attached to the DOM.',
        'vid_mate_check is not defined',
        'Attempting to configurable attribute of unconfigurable property.',
        /Blocked a frame with origin/,
        'window.cobrowseIOgetRedactedElementsBounds is not a function',
        // VirtualDom issues caused by DOM-altering browser plugins
        'can\'t access property "childNodes"',
        'replaceData is not a function',
        't is undefined',
        't.setAttribute is not a function',
        'undefined has no properties',
        "undefined is not an object (evaluating 't.childNodes')",
        "Argument 1 ('child') to Node.removeChild must be an instance of Node",
        "Cannot read property 'childNodes' of undefined",
        "Cannot read property 'elm_event_node_ref' of undefined",
        "Cannot read property 'elmFs' of undefined",
        "Cannot read property 'value' of undefined",
        "Failed to execute 'removeChild' on 'Node': parameter 1 is not of type 'Node'.",
        // Issues caused by Sentry browser compatibility
        'window.webkit.messageHandlers.selectedDebugHandler',
        // These seem to be caused by Outlook
        // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
        'Object Not Found Matching Id',
        // Network errors such as going offline or being blocked by a proxy
        'Failed to fetch',
      ],
      allowUrls: [/https:\/\/(.*\.)?bellroy\.(com|it)/],
      beforeSend: (event) => {
        // Occasionally, a client would enter an infinite loop of errors which would
        // cause misleading statistics on Sentry. This avoids that scenario.
        if (!firstSeenFilter.isFirstTimeSeen(event) || containsViteLegacyIgnoreBreadcrumb(event)) {
          return null;
        } else {
          return event;
        }
      },
      ...config,
    });
  }
}
