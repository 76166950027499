class NavCategoryStrip extends HTMLElement {
  private selectorsToObserve: string = "li:nth-child(3n+2)";
  private containerSelector: string = "nav";
  private slides: NodeListOf<SlideElement>;
  private container: HTMLElement;
  private dots: Array<Node>;

  constructor() {
    super();
  }
  connectedCallback(): void {
    this.selectorsToObserve = this.getAttribute(
      "data-observe-element-selector",
    );
    this.containerSelector = this.getAttribute("data-container-selector");
    this.slides = this.querySelectorAll(this.selectorsToObserve);
    this.container = this.querySelector(this.containerSelector);
    this.renderDots();
    this.observe();
  }

  renderDots(): void {
    const templateElement = this.querySelector("template");
    const template = templateElement.content.childNodes.length
      ? templateElement.content
      : templateElement;
    const dotTemplate = template.querySelector(".dot");
    const wrapperTemplate = template.querySelector(".wrapper");
    const wrapperElement = wrapperTemplate.cloneNode(false);
    this.dots = [];
    this.slides.forEach((slide, i) => {
      const dot = dotTemplate.cloneNode(true);
      dot.addEventListener("click", (e) => {
        e.preventDefault();
        slide.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      });
      this.dots.push(dot);
    });
    this.dots.forEach((d) => wrapperElement.appendChild(d));
    this.appendChild(wrapperElement);
  }

  observe(): void {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          this.dots[entries[0].target.index].classList.add("active");
        } else {
          this.dots[entries[0].target.index].classList.remove("active");
        }
      },
      { root: this.container, threshold: 1 },
    );

    this.slides.forEach((s, i) => {
      s.index = i;
      observer.observe(s);
    });
  }
}

customElements.define("bellroy-nav-category-carousel", NavCategoryStrip);
