import isSupportedConfigurationFormatVersion from './isSupportedConfigurationFormatVersion';

export type ProductDetailSources = {
  products: {
    source: string;
    host: string;
  };
  'product-images': {
    source: string;
    host: string;
  };
  orderabilities: {
    source: string;
    host: string;
    path: string[];
  };
};

export type Applications = {
  audiences: {
    host: string;
  };
  cart: {
    enabled: boolean;
    host: string;
  };
  'product-detail': {
    sources: ProductDetailSources;
  };
  retailers: {
    origin: string;
  };
};

export type Configuration = {
  applications: Applications;
  branch: string;
  environment: string;
  release: string;
};

export function isConfiguration(v: any): v is Configuration {
  // We control the format version so it is fairly safe to assume that if the version matches then
  // the format was correct.
  return (
    typeof v === 'object' && v !== null && isSupportedConfigurationFormatVersion(v.format_version)
  );
}

export async function fetch(): Promise<Configuration> {
  const configurationResponse = await window.fetch(`/api/v1/customer/settings`);
  if (configurationResponse.ok) {
    const configuration = await configurationResponse.json();
    if (isConfiguration(configuration)) {
      return configuration;
    } else {
      throw new Error('Unexpected configuration response');
    }
  } else {
    throw new Error('Could not fetch configuration');
  }
}
