import isSupportedConfigurationFormatVersion from './isSupportedConfigurationFormatVersion';

export type AbTestEnrolment = {
  experiment_identifier: string;
  enrolled_in_variant: string;
};

export type ShippingLocation = {
  city: string | null;
  country_code: string;
  region_code: string | null;
};

export type CommerceInformation = {
  ab_test_enrolments: AbTestEnrolment[];
  cart_service_host: string;
  continuation_token: string;
  currency_identifier: string;
  enrolled_promotional_campaign_identifiers: string[];
  locale: string;
  price_group: string;
  price_list_identifier: string;
  shipping_to: ShippingLocation;
};

export type PaymentMethodConfig = {
  standard: boolean;
  accelerated?: boolean;
};

export type PaymentMethods = {
  credit_card: PaymentMethodConfig;
  paypal: PaymentMethodConfig;
  apple_pay: PaymentMethodConfig;
  google_pay: PaymentMethodConfig;
};

export type CheckoutFormProperties = {
  braintree_account_id: string;
  braintree_merchant_account_id: string;
  currency_code: string;
  current_location_country_code: string;
  payment_methods: PaymentMethods;
  shipping_to_country_code: string;
  shipping_to_region_code: string;
};

export type Context = {
  checkout_form_properties: CheckoutFormProperties;
  commerce_information: CommerceInformation;
};

export function isContext(v: any): v is Context {
  // We control the format version so it is fairly safe to assume that if the version matches then
  // the format was correct.
  return (
    typeof v === 'object' && v !== null && isSupportedConfigurationFormatVersion(v.format_version)
  );
}

export async function fetch(): Promise<Context> {
  const contextResponse = await window.fetch(`/api/v1/customer/context`);
  if (contextResponse.ok) {
    const context = await contextResponse.json();
    if (isContext(context)) {
      return context;
    } else {
      throw new Error('Unexpected context response');
    }
  } else {
    throw new Error('Could not fetch context');
  }
}
