class SubCategoryCarousel extends HTMLElement {
  connectedCallback(): void {
    document
      .querySelectorAll(".subcategoryCarousel a")
      .forEach(function (item) {
        if (window.location.pathname === item.getAttribute("href")) {
          item.classList.add("active");
        }
      });
  }
}

customElements.define("bellroy-sub-category-carousel", SubCategoryCarousel);
